import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import CloseIcon from '@mui/icons-material/Close';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { FilledInputProps } from '@mui/material/FilledInput';
import { useSelector } from 'react-redux';
import { EBrowserType } from '@provider-types/enums';
import { RootState } from '@provider-reducers/root';

export interface Props extends ComponentProps<typeof TextField> {
  value: string;
  chainedBtnId?: string;
  // mainColor?: string;
  // secondaryColor?: string;
  backgroundColor?: string;
  StartIcon?: JSX.Element | (OverridableComponent<SvgIconTypeMap<any, 'svg'>> & { muiName: string });
  EndIcon?: JSX.Element | (OverridableComponent<SvgIconTypeMap<any, 'svg'>> & { muiName: string });
  hasClearIcon?: boolean;
  isValid?: boolean;
  errorMessage?: string | JSX.Element | null;
  setValue: (value: string) => void;
}

export const Input: React.FC<Props> = ({
  value,
  backgroundColor,
  chainedBtnId = '',
  StartIcon,
  EndIcon,
  hasClearIcon = false,
  isValid = true,
  errorMessage = 'unspecified error',
  onFocus,
  onBlur,
  setValue,
  disabled,
  label,
  ...rest
}) => {
  const inputId = useRef(Math.random().toString());
  const [focused, setFocused] = useState(false);

  const InputProps: Partial<FilledInputProps> = {
    style: {
      fontSize: '16px'
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const { browserType } = useSelector((state: RootState) => state.settings);

  const labelColor = disabled ? '#5B6B7B' : isValid ? (focused ? '#46AFF2' : '#BEC3CC') : '#FF787A';

  if (StartIcon && browserType === EBrowserType.DESKTOP)
    InputProps.startAdornment = <InputAdornment position='start'>{StartIcon}</InputAdornment>;
  if (!isValid) {
    InputProps.endAdornment = (
      <ErrorOutlineIcon
        sx={{
          boxSizing: 'content-box',
          color: '#FF787A',
          width: '24px',
          height: '24px',
          paddingRight: '8px',
          paddingTop: '16px',
          paddingBottom: '16px'
        }}
      />
    );
  }
  // clear icon can only be displayed if there is no error, error
  // icon takes precedence
  else if (hasClearIcon && value && value.trim().length > 0) {
    InputProps.endAdornment = (
      <InputAdornment position='end'>
        <IconButton onClick={() => setValue('')}>
          <CloseIcon sx={{ color: '#ffffff' }} />
        </IconButton>
      </InputAdornment>
    );
  }
  // if there is no error icon or clear icon, display the optionally provided EndIcon
  else if (EndIcon) {
    InputProps.endAdornment = <InputAdornment position='end'>{EndIcon}</InputAdornment>;
  }

  useEffect(() => {
    const input = document.getElementById(inputId.current);

    if (input) {
      input.addEventListener('keypress', function (event) {
        if (event.key === 'Enter') {
          event.preventDefault();
          document.getElementById(chainedBtnId)?.click();
        }
      });
    }
  }, [chainedBtnId]);

  return (
    <>
      <TextField
        id={inputId.current}
        disabled={disabled}
        sx={{
          width: '100%',
          backgroundColor: ({ palette }) => backgroundColor || palette?.background?.default || '#1A1F24',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          borderRadius: '4px 4px 0px 0px',
          '& .MuiFormLabel-root': {
            fontWeight: 800,
            lineHeight: 1,
            color: labelColor,
            fontSize: '12px',
            top: '4px',
            left: StartIcon && browserType === EBrowserType.DESKTOP ? '32px' : '0px'
          },
          '& .MuiInputBase-input': {
            color: '#ffffff',
            paddingLeft: StartIcon ? 0 : '',
            paddingTop: label ? '25px' : '',
            paddingBottom: label ? '9px' : ''
          },
          '&:hover': {
            backgroundColor: ({ palette }) => (disabled ? '' : backgroundColor || palette?.background?.secondary || '')
          },
          '&:focus-within': {
            backgroundColor: ({ palette }) => backgroundColor || palette?.background?.default || ''
          },
          '& .MuiFilledInput-root:before': {
            borderBottomColor: labelColor
          }
        }}
        hiddenLabel={true}
        label={label}
        variant='filled'
        InputProps={InputProps}
        value={value}
        onFocus={evt => {
          setFocused(true);
          onFocus && onFocus(evt);
        }}
        onBlur={evt => {
          setFocused(false);
          onBlur && onBlur(evt);
        }}
        onChange={handleChange}
        {...rest}
      />
      {!isValid ? (
        <div
          style={{
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '19px',
            color: '#FF787A',
            marginTop: '2px'
          }}
        >
          {errorMessage}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};
