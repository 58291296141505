import React from 'react';
import { FallbackPage } from '@provider-components';
import { ErrorBoundaryFallback } from '@provider-components/ErrorBoundaryFallback';
import { ESitterRouteNames } from './route-names';
import AppThemeProvider from '@provider-app/AppThemeProvider';

const Esitter = React.lazy(() => import('../screens/Esitter'));

export const ESitterRoutes = [
  {
    path: ESitterRouteNames.Esitter,
    element: (
      <React.Suspense fallback={<FallbackPage />}>
        <AppThemeProvider>
          <Esitter />
        </AppThemeProvider>
      </React.Suspense>
    ),
    errorElement: <ErrorBoundaryFallback />
  }
];
