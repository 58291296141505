import React from 'react';
import { VRCallRouteNames } from './route-names';
import { FallbackPage } from '@provider-components';
import { ErrorBoundaryFallback } from '@provider-components/ErrorBoundaryFallback';
import JoinVR from '../components/JoinVR/JoinVR';

const VRCall = React.lazy(() => import('../screens/VRCall'));

export const VRCallRoutes = [
  {
    path: VRCallRouteNames.VRCall,
    element: (
      <React.Suspense fallback={<FallbackPage />}>
        <JoinVR>
          <VRCall />
        </JoinVR>
      </React.Suspense>
    ),
    errorElement: <ErrorBoundaryFallback />
  }
];
