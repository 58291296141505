import { useDebounceEffect } from 'ahooks';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

type UseSyncVolumeChangeProps = {
  value: number;
  onChange: (event: any) => void;
};

type ReturnType = {
  desiredValue: number;
  handleChange: (event: any, value: number | number[]) => void;
  handlePointerDown: () => void;
  handlePointerUp: () => void;
};

const useSyncVolumeChange = ({ value, onChange }: UseSyncVolumeChangeProps): ReturnType => {
  const [desiredValue, setDesiredValue] = useState(value);
  const [isDragging, setIsDragging] = useState(false);
  const [canSafalySync, setCanSafalySync] = useState(true);

  const debouncedHandleChange = useDebouncedCallback(
    (_, value) => {
      onChange({ target: { value } });
    },
    250,
    { leading: true }
  );

  const handleChange = useCallback(
    (event, value) => {
      const sanitizedValue = Array.isArray(value) ? value[0] : value;

      setDesiredValue(sanitizedValue);

      if (isDragging) {
        setCanSafalySync(false);
        debouncedHandleChange(event, sanitizedValue);
      }
    },
    [debouncedHandleChange, isDragging]
  );

  useEffect(() => {
    if (desiredValue === value) {
      setCanSafalySync(true);
    }
  }, [desiredValue, value]);

  useDebounceEffect(
    () => {
      if (canSafalySync) {
        setDesiredValue(value);
      }
    },
    [value, canSafalySync],
    { wait: 250 }
  );

  const handlePointerDown = () => {
    setIsDragging(true);
  };

  const handlePointerUp = () => {
    setIsDragging(false);
  };

  return { desiredValue, handleChange, handlePointerDown, handlePointerUp };
};

export default useSyncVolumeChange;
