/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import { debounce } from 'lodash';
import { CameraListItem } from '@provider-types/provider';
import styles from './CameraControl.module.css';

import ArrowIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import { SelectCameraDropdown } from '../SelectCameraDropdown/SelectCameraDropdown';
import Box from '@mui/material/Box';

import { setIsCameraControlHovered } from '@provider-reducers/appSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const debouncedFunction = debounce(
  (value: any, func: any) => {
    func(value);
  },
  250,
  {
    leading: true,
    maxWait: 250
  }
);

export interface CameraControlProps {
  focusElementId?: string;
  rangeValue: number;
  rangeMin?: number;
  rangeMax?: number;
  rangeLimits: { min: boolean; max: boolean };
  verticalLimits: { min: boolean; max: boolean };
  horizontalLimits: { min: boolean; max: boolean };
  cameraList: CameraListItem[];
  selectedCameraUuid: string;
  onArrowUpClick: () => any;
  onArrowRightClick: () => any;
  onArrowBottomClick: () => any;
  onArrowLeftClick: () => any;
  onHomeButtonClick: () => any;
  onRangeValueChange: (e: any) => any;
  onCameraChange: (cameraUuid: string) => void;
  isScrollToZoom?: boolean;
  desiredScrollZoomValue?: number;
  setDeterminedDesiredZoomValue: (e: any) => void;
  isKeysToZoom?: boolean;
  determinedDesiredZoomValue?: number;
  isCameraControlActive?: boolean;
}

const CameraControl = ({
  focusElementId,
  rangeValue,
  rangeMin = 0,
  rangeMax = 100,
  verticalLimits,
  horizontalLimits,
  cameraList = [],
  selectedCameraUuid = '',
  onArrowUpClick,
  onArrowRightClick,
  onArrowBottomClick,
  onArrowLeftClick,
  onHomeButtonClick,
  onRangeValueChange,
  onCameraChange,
  isScrollToZoom = false,
  desiredScrollZoomValue = 0,
  setDeterminedDesiredZoomValue,
  determinedDesiredZoomValue = 0,
  isKeysToZoom = false,
  isCameraControlActive = false
}: CameraControlProps) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const getOpacityForLimit = useCallback((limitReached: boolean) => (limitReached ? 0.5 : 1), []);

  const [desiredValue, setDesiredValue] = useState(rangeValue);

  const [isThumbAttached, setIsThumbAttached] = useState(true);

  const debouncedOnRangeValueChange = useCallback(
    value => debouncedFunction(value, onRangeValueChange),
    [onRangeValueChange]
  );

  const internalOnHomeButtonClick = useCallback(() => {
    setIsThumbAttached(true);
    onHomeButtonClick();
  }, [setIsThumbAttached, onHomeButtonClick]);

  const internalOnRangeValueChange = useCallback(
    (e: any) => {
      setDeterminedDesiredZoomValue(e.target.value);
      setDesiredValue(e.target.value);
      debouncedOnRangeValueChange(e);
    },
    [setDesiredValue, debouncedOnRangeValueChange, setDeterminedDesiredZoomValue]
  );

  const internalRangeMouseDown = useCallback(() => {
    setIsThumbAttached(false);
  }, [setIsThumbAttached]);

  const internalRangeMouseUp = useCallback(() => {
    const activeElement = document.activeElement as HTMLSpanElement;
    activeElement?.blur();
    if (focusElementId) {
      const focusElement = document.getElementById(focusElementId);
      focusElement?.focus();
    }
  }, [focusElementId]);

  useEffect(() => {
    if (isScrollToZoom && desiredScrollZoomValue) {
      setDesiredValue(desiredScrollZoomValue);
    }
  }, [desiredScrollZoomValue, isScrollToZoom]);

  useEffect(() => {
    if (isKeysToZoom && determinedDesiredZoomValue) {
      setDesiredValue(determinedDesiredZoomValue);
    }
  }, [determinedDesiredZoomValue, isKeysToZoom]);

  useEffect(() => {
    if (rangeValue === desiredValue && !isThumbAttached) {
      setIsThumbAttached(true);
    } else if (isThumbAttached && desiredValue !== rangeValue) {
      setDesiredValue(rangeValue);
    }
  }, [rangeValue, desiredValue, isThumbAttached, setIsThumbAttached]);

  /** reset when camera changes */
  useEffect(() => {
    setIsThumbAttached(true);
  }, [selectedCameraUuid]);

  const selectedCamera = cameraList.find(c => c.uuid === selectedCameraUuid);

  return (
    <div
      className={styles.patientControl}
      onMouseEnter={() => dispatch(setIsCameraControlHovered(true))}
      onMouseLeave={() => dispatch(setIsCameraControlHovered(false))}
      data-testid='camera-control-popup'
    >
      {selectedCamera?.supportedFeatures?.pan && selectedCamera?.supportedFeatures?.tilt && (
        <div className={styles.controlContainer}>
          <div
            aria-label={t('cameraControl.cameraUp')}
            className={`${styles.forwardArrowButton} ${styles.cameraUpButton}`}
            onClick={verticalLimits.max ? undefined : onArrowUpClick}
          >
            <ArrowIcon
              className={styles.forwardArrow}
              sx={{
                width: 16,
                height: 16,
                color: '#ffffff',
                transform: 'rotate(-135deg)',
                opacity: getOpacityForLimit(verticalLimits.max)
              }}
            />
          </div>
          <div
            aria-label={t('cameraControl.cameraRight')}
            className={`${styles.forwardArrowButton} ${styles.cameraRightButton}`}
            onClick={horizontalLimits.max ? undefined : onArrowRightClick}
          >
            <ArrowIcon
              sx={{
                width: 16,
                height: 16,
                color: '#ffffff',
                opacity: getOpacityForLimit(horizontalLimits.max),
                transform: 'rotate(-45deg)'
              }}
            />
          </div>
          <div
            aria-label={t('cameraControl.cameraDown')}
            className={`${styles.forwardArrowButton} ${styles.cameraDownButton}`}
            onClick={verticalLimits.min ? undefined : onArrowBottomClick}
          >
            <ArrowIcon
              sx={{
                width: 16,
                height: 16,
                color: '#ffffff',
                transform: 'rotate(45deg)',
                opacity: getOpacityForLimit(verticalLimits.min)
              }}
            />
          </div>
          <div
            aria-label={t('cameraControl.cameraLeft')}
            className={`${styles.forwardArrowButton} ${styles.cameraLeftButton}`}
            onClick={horizontalLimits.min ? undefined : onArrowLeftClick}
          >
            <ArrowIcon
              sx={{
                width: 16,
                height: 16,
                color: '#ffffff',
                transform: 'rotate(135deg)',
                opacity: getOpacityForLimit(horizontalLimits.min)
              }}
            />
          </div>
          <div
            className={styles.homeContainer}
            onClick={internalOnHomeButtonClick}
            aria-label={t('cameraControl.home')}
          >
            <HomeIcon sx={{ color: '#ffffff', width: '14px', height: '14px' }} />
          </div>
        </div>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px' }}>
        {selectedCamera?.supportedFeatures?.zoom && (
          <>
            <div className={styles.rangeContainer}>
              <div style={{ position: 'relative', width: '106px', height: '30px', margin: 'auto 0' }}>
                <div style={{ position: 'absolute' }}>
                  <Slider
                    sx={{
                      width: '106px',
                      '& .MuiSlider-thumb': {
                        opacity: 0
                      },
                      '& .MuiSlider-rail': {
                        color: '#BEC3CC',
                        height: '4px',
                        borderRadius: '8px',
                        opacity: 1
                      },
                      '& .MuiSlider-track': {
                        color: '#1774CC',
                        height: '4px',
                        borderRadius: '8px'
                      }
                    }}
                    slotProps={{
                      root: { 'aria-label': 'cameraTrack' }
                    }}
                    defaultValue={rangeMin}
                    aria-label='Default'
                    valueLabelDisplay='off'
                    min={rangeMin}
                    max={rangeMax}
                    value={rangeValue}
                  />
                </div>
                <div style={{ position: 'absolute' }}>
                  <Slider
                    sx={{
                      width: '106px',
                      '& .MuiSlider-thumb': {
                        color: '#1774CC'
                      },
                      '& .MuiSlider-rail': {
                        opacity: 0
                      },
                      '& .MuiSlider-track': {
                        opacity: 0
                      }
                    }}
                    defaultValue={rangeMin}
                    aria-label='zoom-slider'
                    valueLabelDisplay='off'
                    min={rangeMin}
                    max={rangeMax}
                    track={false}
                    value={isThumbAttached ? rangeValue : desiredValue}
                    onChange={internalOnRangeValueChange}
                    onMouseDown={internalRangeMouseDown}
                    onMouseUp={internalRangeMouseUp}
                  />
                </div>
              </div>
              <Box
                data-testid='cameraValue'
                sx={{ textAlign: 'right', marginLeft: '8px', fontSize: '14px', minWidth: '36px' }}
              >{`${rangeValue}%`}</Box>
            </div>
          </>
        )}

        {isCameraControlActive && (
          <Box sx={{ mb: 4, marginBottom: 0, width: '100%' }}>
            <SelectCameraDropdown
              cameraList={cameraList}
              selectedCameraUuid={selectedCameraUuid}
              onCameraChange={onCameraChange}
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default CameraControl;
