import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useWebsocketHelper } from 'src/wsHelper';
import { EmbedRouteNames } from '@provider-features/embed/router';
import { LocationState } from '@provider-types/page';

const JoinVR: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const { wsReady } = useWebsocketHelper();
  const location = useLocation();
  const { isParticipant, joinUuid, call } = (location?.state || {}) as LocationState;

  if (isParticipant && !wsReady) {
    if (call?.callUuid && joinUuid) {
      return <Navigate to={`${EmbedRouteNames.Participant}/${call.callUuid}/${joinUuid}`} />;
    }

    return <Navigate to={EmbedRouteNames.CallEnded} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default JoinVR;
