import React from 'react';
import { Participant, ParticipantEmpty, Begin, CallEnded } from '../screens';
import { EmbedRouteNames } from './route-names';
import { ErrorBoundaryFallback } from 'src/components/ErrorBoundaryFallback';

export const EmbedRoutes = [
  {
    path: `${EmbedRouteNames.Participant}/:callId/:otpToken`,
    element: <Participant />,
    errorElement: <ErrorBoundaryFallback />
  },
  {
    path: EmbedRouteNames.Participant,
    element: <ParticipantEmpty />,
    errorElement: <ErrorBoundaryFallback />
  },
  {
    path: `${EmbedRouteNames.Begin}/:callUuid/:loginToken`,
    element: <Begin />,
    errorElement: <ErrorBoundaryFallback />
  },
  {
    path: EmbedRouteNames.CallEnded,
    element: <CallEnded />,
    errorElement: <ErrorBoundaryFallback />
  }
];
