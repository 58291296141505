import React from 'react';
import { useTranslation } from 'react-i18next';

export function ErrorBoundaryFallback(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        fontSize: '1.5rem',
        padding: '2rem'
      }}
    >
      <h2 style={{ marginBottom: '0.5rem', fontSize: '2rem', fontWeight: '700' }}>
        {t('errorBoundaryFallback.title')}
      </h2>
      <p>{t('errorBoundaryFallback.description')}</p>
    </div>
  );
}
